import { takeEvery, call, put, all, select } from 'redux-saga/effects'
import {
  dashboardApi,
  curriculumSequencesApi,
  assignmentApi,
  userContextApi,
} from '@edulastic/api'
import * as Sentry from '@sentry/browser'
import { captureSentryException, notification } from '@edulastic/common'
import { createAction, createReducer } from 'redux-starter-kit'
import { createSelector } from 'reselect'
import configurableTilesApi from '@edulastic/api/src/configurableTiles'
import {
  ITEM_GROUP_TYPES,
  testCategoryTypes,
} from '@edulastic/constants/const/test'
import { test as testConstants, tutorials } from '@edulastic/constants'
import { getUserDetails, setClassToUserAction } from '../../student/Login/ducks'
import {
  getCurrentActiveTermIds,
  getCurrentTerm,
  getUserId,
  getUserOrgId,
  isGcpsDistrictSelector,
} from '../src/selectors/user'
import { FEATURE_BANNER } from '../FeatureBanner/utils'
import { hasRandomQuestions } from '../ClassBoard/utils'
import { viewedTutorialsSelector } from '../../tutorials/tutorialReducer'
import { DASHBOARD_VERSION, TAB_KEYS } from './constants'

const RECEIVE_TEACHER_DASHBOARD_REQUEST =
  '[dashboard teacher] receive data request'
const RECEIVE_TEACHER_DASHBOARD_SUCCESS =
  '[dashboard teacher] receive data success'
const RECEIVE_TEACHER_DASHBOARD_ERROR = '[dashboard teacher] receive data error'
const SET_TOTAL_ASSIGNMENT_COUNT_GROUPED_BY_TEST =
  '[dashboard teacher] set total assignment count by test'
const LAUNCH_HANGOUT_OPEN = '[dashboard teacher] launch hangouts open'
const LAUNCH_HANGOUT_CLOSE = '[dashboard teacher] launch hangouts close'

const FETCH_DASHBOARD_TILES = '[dashboard teacher] fetch tiles data'
const SET_DASHBOARD_TILES = '[dashboard teacher] set tiles data'
const SET_TRIAL = '[dashboard teacher] set trial'
const FETCH_PLAYLIST = '[dashboard teacher] fetch playlists'
const FETCH_PLAYLIST_SUCCESS = '[dashboard teacher] fetch playlists success'
const UPDATE_FAVORITE_CLASSES = '[dashboard teacher] update favorite classes'
const TOGGLE_FAVORITE_CLASS = '[dashboard teacher] toggle favorite classes'
const SET_FEATURE_BANNER = '[feature banner] set feature banner'

export const SET_SHOW_WELCOME = '[dashboard teacher] set show welcome'
export const SET_SHOW_GET_STARTED = '[dashboard teacher] set show get started'
export const SET_SHOW_JOIN_SCHOOL = '[dashboard teacher] set show join school'
const SET_SHOW_CLASS_CREATION = '[dashboard teacher] set show class creation'
const SET_CLASS_FILTERS = '[dashboard teacher] set class filters'
export const SET_SHOW_ASSIGNMENT_CREATION =
  '[dashboard teacher] set show assignment creation'
export const GET_DASHBOARD_ASSIGNMENTS = '[dashboard teacher] get assignments'
export const SET_DASHBOARD_ASSIGNMENTS = '[dashboard teacher] set assignments'
export const SET_TOTAL_ASSIGNMENT_COUNT = '[dashboard teacher] set total count'
export const DASBHBOARD_ASSIGNMENTS_ERROR =
  '[dashboard teacher] dashboard assignments fetch error'
export const SET_DASHBOARD_SETTINGS =
  '[dashboard teacher] set dashboard settings'
export const UPDATE_DASHBOARD_SETTINGS =
  '[dashboard teacher] update dashboard settings'
export const SET_PRIVACY_SEEN = '[dashboard teacher] set privacy modal seen'
export const SET_GUIDE_SEEN = '[dashboard teacher] set quick start guide seen'
const SET_USER_CONTEXTS_LOADING =
  '[dashboard teacher] set user contexts loading'

const SET_PROVIDER_NAMES = '[dashboard teacher] set provider names'
const SET_IS_CLEVER_CLASS_EXISTS = '[dashboard teacher] set clever class exists'

const SET_CLASS_VIEW = '[dashboard teacher] set class view'
const SET_NEW_DASHBOARD_ACTIVE_TAB =
  '[dashboard teacher] set new dashboard active tab'
const SET_TOTAL_CLASSES = '[dashboard teacher] set total classes'

export const setUserContextsLoadingAction = createAction(
  SET_USER_CONTEXTS_LOADING
)
export const setNewDashboardActiveTabAction = createAction(
  SET_NEW_DASHBOARD_ACTIVE_TAB
)
export const receiveTeacherDashboardAction = createAction(
  RECEIVE_TEACHER_DASHBOARD_REQUEST
)
export const receiveTeacherDashboardSuccessAction = createAction(
  RECEIVE_TEACHER_DASHBOARD_SUCCESS
)
export const receiveTeacherDashboardErrorAction = createAction(
  RECEIVE_TEACHER_DASHBOARD_ERROR
)
export const setTotalAssignmentConutAction = createAction(
  SET_TOTAL_ASSIGNMENT_COUNT
)
export const launchHangoutOpen = createAction(LAUNCH_HANGOUT_OPEN)
export const launchHangoutClose = createAction(LAUNCH_HANGOUT_CLOSE)

export const fetchDashboardTiles = createAction(FETCH_DASHBOARD_TILES)
export const setDashboardTiles = createAction(SET_DASHBOARD_TILES)
export const setFeatureBanner = createAction(SET_FEATURE_BANNER)

export const setTrial = createAction(SET_TRIAL)

export const fetchPlaylistsAction = createAction(FETCH_PLAYLIST)
export const fetchPlaylistsSuccessAction = createAction(FETCH_PLAYLIST_SUCCESS)

export const updatefavoriteClassesAction = createAction(UPDATE_FAVORITE_CLASSES)
export const togglefavoriteClassAction = createAction(TOGGLE_FAVORITE_CLASS)

export const setShowWelcomePopupAction = createAction(SET_SHOW_WELCOME)
export const setShowGetStartedModalAction = createAction(SET_SHOW_GET_STARTED)
export const setShowJoinSchoolModalAction = createAction(SET_SHOW_JOIN_SCHOOL)
export const setShowClassCreationModalAction = createAction(
  SET_SHOW_CLASS_CREATION
)
export const setShowAssignmentCreationModalAction = createAction(
  SET_SHOW_ASSIGNMENT_CREATION
)

export const setClassFiltersAction = createAction(SET_CLASS_FILTERS)

export const getDashboardAssigmentsAction = createAction(
  GET_DASHBOARD_ASSIGNMENTS
)

export const setPrivacyModalSeenAction = createAction(SET_PRIVACY_SEEN)
export const setQuickStartGuideSeenAction = createAction(SET_GUIDE_SEEN)
export const setDashBoardAssignmentsAction = createAction(
  SET_DASHBOARD_ASSIGNMENTS
)
export const setAssignmentsByTestAction = createAction(
  SET_TOTAL_ASSIGNMENT_COUNT_GROUPED_BY_TEST
)

export const setDashboardAssignmentsErrorAction = createAction(
  DASBHBOARD_ASSIGNMENTS_ERROR
)

export const setDashboardSettingsAction = createAction(SET_DASHBOARD_SETTINGS)
export const setProviderNamesAction = createAction(SET_PROVIDER_NAMES)
export const setIsCleverClassExistsAction = createAction(
  SET_IS_CLEVER_CLASS_EXISTS
)
export const setClassViewAction = createAction(SET_CLASS_VIEW)
export const setTotalClassesAction = createAction(SET_TOTAL_CLASSES)

export const stateSelector = (state) => state.dashboardTeacher

export const getLaunchHangoutStatus = createSelector(
  stateSelector,
  (state) => state.isLaunchHangoutOpen
)
export const getDashboardTilesSelector = createSelector(
  stateSelector,
  (state) => state.configurableTiles
)

export const getDashboardPlaylists = createSelector(
  stateSelector,
  (state) => state.playlists
)

export const getDashboardClasses = createSelector(
  stateSelector,
  (state) => state.data
)

export const getShowWelcomePopupSelector = createSelector(
  stateSelector,
  (state) => state.showWelcomePopup
)
export const getShowGetStartedModalSelector = createSelector(
  stateSelector,
  (state) => state.showGetStartedModal
)
export const getShowJoinSchoolModalSelector = createSelector(
  stateSelector,
  (state) => state.showJoinSchoolModal
)
export const getShowClassCreationModalSelector = createSelector(
  stateSelector,
  (state) => state.showClassCreationModal
)
export const getShowAssignmentCreationModalSelector = createSelector(
  stateSelector,
  (state) => state.showAssignmentCreationModal
)

export const getFeatureBannerTiles = createSelector(
  stateSelector,
  (state) => state.featureBanners
)

const cliBannerVisibleChecker = () =>
  !sessionStorage.cliBannerShown && (sessionStorage.cliBannerVisible || false)

export const getDashboardJoyrideVisbile = createSelector(
  stateSelector,
  viewedTutorialsSelector,
  isGcpsDistrictSelector,
  cliBannerVisibleChecker,
  (
    {
      dashboardSettings,
      showWelcomePopup,
      showGetStartedModal,
      showJoinSchoolModal,
      privacyModalSeen,
      quickStartGuideSeen,
    },
    viewedTutorials,
    isGcpsDistrict,
    cliBannerVisible
  ) => {
    const { dashboardVersion, preselectedUser } = dashboardSettings
    const isSignUpCompleted =
      !showWelcomePopup &&
      !showGetStartedModal &&
      !showJoinSchoolModal &&
      privacyModalSeen &&
      quickStartGuideSeen &&
      !cliBannerVisible
    const canShowOldDashboardTutorial =
      !preselectedUser &&
      !dashboardVersion &&
      !viewedTutorials.includes(tutorials.dashboardToggle)
    const canShowNewDashboardTutorial =
      dashboardVersion &&
      !viewedTutorials.includes(tutorials.newDashboardOverviewSteps)
    return (
      !isGcpsDistrict &&
      isSignUpCompleted &&
      (canShowOldDashboardTutorial || canShowNewDashboardTutorial)
    )
  }
)

export const getTotalAssignmentCount = createSelector(
  stateSelector,
  (state) => state.allAssignmentCount
)

export const isTeacherDashboardLoading = createSelector(
  stateSelector,
  (state) => state.loading
)
export const getClassFilters = createSelector(
  stateSelector,
  (state) => state.classFilters
)

export const getIsClassLoading = createSelector(
  stateSelector,
  (state) => state.classLoading
)

export const getDashboardAssignmentsLoadingSelector = createSelector(
  stateSelector,
  (state) => state.dashboardAssignmentsLoading
)

export const getDashboardAssignmentsDataSelector = createSelector(
  stateSelector,
  (state) => state.assignmentsData
)

export const getDashboardSettingsSelector = createSelector(
  stateSelector,
  (state) => state.dashboardSettings
)

export const getProviderNames = createSelector(
  stateSelector,
  (state) => state.providerNames
)

export const getIsCleverClassExists = createSelector(
  stateSelector,
  (state) => state.isCleverClassExists
)

export const getClassView = createSelector(
  [stateSelector, getUserId],
  (state, userId) =>
    state.classView || localStorage.getItem(`classView:${userId}`)
)
export const getDashboardVersion = createSelector(
  stateSelector,
  (state) => state.dashboardSettings.dashboardVersion
)

export const getUserContextsLoading = createSelector(
  stateSelector,
  (state) => state.userContextLoading
)

export const getNewDashboardActiveTab = createSelector(
  stateSelector,
  (state) => state.newDashboardActiveTab
)

export const getTotalClassesSelector = createSelector(
  stateSelector,
  (state) => state.totalClasses
)
export const getAssignmentsCountSelector = createSelector(
  stateSelector,
  (state) => state.totalAssignments
)

const initialState = {
  data: [],
  error: null,
  loading: false,
  isLaunchHangoutOpen: false,
  isAddingTrial: false,
  configurableTiles: JSON.parse(
    localStorage.getItem('author:dashboard:tiles') || '[]'
  ),
  allAssignmentCount: 0,
  showWelcomePopup: false,
  showGetStartedModal: false,
  showJoinSchoolModal: false,
  showClassCreationModal: false,
  showAssignmentCreationModal: false,
  featureBanners: null,
  classFilters: {},
  classLoading: false,
  dashboardAssignmentsLoading: false,
  assignmentsData: [],
  providerNames: [],
  isCleverClassExists: false,
  classView: '',
  userContextLoading: false,
  dashboardSettings: {
    preselectedUser: false, // Identify beta user when true
    dashboardVersion: DASHBOARD_VERSION.OLD_DASHBOARD,
    feedbackProvided: false, // True once the user submits the feedback
  },
  privacyModalSeen: true,
  quickStartGuideSeen: true,
  newDashboardActiveTab: TAB_KEYS.MY_CLASSES,
  totalClasses: 0,
  totalAssignments: 0,
}

export const reducer = createReducer(initialState, {
  [RECEIVE_TEACHER_DASHBOARD_REQUEST]: (state, { payload }) => {
    state.loading = !payload?.background
    state.classLoading = true
  },
  [RECEIVE_TEACHER_DASHBOARD_SUCCESS]: (state, { payload }) => {
    state.loading = false
    state.classLoading = false
    state.data = payload
  },
  [SET_TOTAL_ASSIGNMENT_COUNT]: (state, { payload }) => {
    state.loading = false
    state.allAssignmentCount = payload
  },
  [RECEIVE_TEACHER_DASHBOARD_ERROR]: (state, { payload }) => {
    state.loading = false
    state.classLoading = false
    state.error = payload.error
  },
  [LAUNCH_HANGOUT_OPEN]: (state) => {
    state.isLaunchHangoutOpen = true
  },
  [LAUNCH_HANGOUT_CLOSE]: (state) => {
    state.isLaunchHangoutOpen = false
  },
  [SET_DASHBOARD_TILES]: (state, { payload }) => {
    state.configurableTiles = payload
  },
  [SET_FEATURE_BANNER]: (state, { payload }) => {
    state.featureBanners = payload || []
  },
  [SET_TRIAL]: (state, { payload }) => {
    state.isAddingTrial = payload
  },
  [SET_PRIVACY_SEEN]: (state, { payload }) => {
    state.privacyModalSeen = payload
  },
  [SET_GUIDE_SEEN]: (state, { payload }) => {
    state.quickStartGuideSeen = payload
  },
  [FETCH_PLAYLIST_SUCCESS]: (state, { payload }) => {
    state.playlists = payload
  },
  [UPDATE_FAVORITE_CLASSES]: (state, { payload }) => {
    state.data = state.data.map((item) => {
      if (item._id === payload.groupId) {
        item.isFavourite = payload.isFavourite
      }
      return item
    })
  },
  [SET_USER_CONTEXTS_LOADING]: (state, { payload }) => {
    state.userContextLoading = payload
  },
  [SET_SHOW_WELCOME]: (state, { payload }) => {
    state.showWelcomePopup = payload
  },
  [SET_SHOW_GET_STARTED]: (state, { payload }) => {
    state.showGetStartedModal = payload
  },
  [SET_SHOW_JOIN_SCHOOL]: (state, { payload }) => {
    state.showJoinSchoolModal = payload
  },
  [SET_SHOW_CLASS_CREATION]: (state, { payload }) => {
    state.showClassCreationModal = payload
  },
  [SET_SHOW_ASSIGNMENT_CREATION]: (state, { payload }) => {
    state.showAssignmentCreationModal = payload
  },
  [SET_CLASS_FILTERS]: (state, { payload }) => {
    state.classFilters = payload
  },
  [GET_DASHBOARD_ASSIGNMENTS]: (state) => {
    state.dashboardAssignmentsLoading = true
  },
  [SET_DASHBOARD_ASSIGNMENTS]: (state, { payload }) => {
    state.assignmentsData = payload
    state.dashboardAssignmentsLoading = false
  },
  [SET_NEW_DASHBOARD_ACTIVE_TAB]: (state, { payload }) => {
    state.newDashboardActiveTab = payload
  },
  [DASBHBOARD_ASSIGNMENTS_ERROR]: (state, { payload }) => {
    state.error = payload
    state.dashboardAssignmentsLoading = false
  },
  [SET_PROVIDER_NAMES]: (state, { payload }) => {
    state.providerNames = payload
  },
  [SET_IS_CLEVER_CLASS_EXISTS]: (state, { payload }) => {
    state.isCleverClassExists = payload
  },
  [SET_CLASS_VIEW]: (state, { payload }) => {
    const { userId, view } = payload
    localStorage.setItem(`classView:${userId}`, view)
    state.classView = view
  },
  [UPDATE_DASHBOARD_SETTINGS]: (state, { payload }) => {
    state.dashboardSettings = payload
  },
  [SET_TOTAL_CLASSES]: (state, { payload }) => {
    state.totalClasses = payload
  },
  [SET_TOTAL_ASSIGNMENT_COUNT_GROUPED_BY_TEST]: (state, { payload }) => {
    state.totalAssignments = payload
  },
})

function* receiveTeacherDashboardSaga({ payload }) {
  try {
    const userId = yield select(getUserId)
    const districtId = yield select(getUserOrgId)
    const currentTermIds = yield select(getCurrentActiveTermIds)
    const defaultTermId = yield select(getCurrentTerm)
    const termId = currentTermIds?.[0] || defaultTermId
    const dashboardVersion = yield select(getDashboardVersion)
    const PAGE_SIZE = 1200
    const pageSize = dashboardVersion === 1 && PAGE_SIZE
    const {
      classDetails,
      totalAssignmentCount = 0,
      totalClasses,
      providerNames,
      isCleverClassExists,
    } = yield call(
      dashboardApi.getTeacherDashboardDetails,
      localStorage.getItem(
        `author:dashboard:classFilter:${userId}:${districtId}`
      ),
      pageSize,
      termId
    )
    yield put(receiveTeacherDashboardSuccessAction(classDetails))
    if (payload?.updateUserClassList) {
      yield put(setClassToUserAction(classDetails))
    }
    yield put(setTotalAssignmentConutAction(totalAssignmentCount))
    yield put(setProviderNamesAction(providerNames))
    yield put(setIsCleverClassExistsAction(isCleverClassExists))
    yield put(setTotalClassesAction(totalClasses))
    payload?.setClassType?.()
  } catch (err) {
    const errorMessage = 'Unable to fetch dashboard details.'
    notification({ type: 'error', msg: errorMessage })
    yield put(receiveTeacherDashboardErrorAction({ error: errorMessage }))
  }
}

function* toggleFavoriteClassSaga({ payload }) {
  try {
    yield put(
      updatefavoriteClassesAction({
        groupId: payload.groupId,
        isFavourite: payload.toggleValue,
      })
    )
    const result = yield call(dashboardApi.toggleFavouriteClass, {
      groupId: payload.groupId,
      toggleValue: payload.toggleValue,
    })
    if (result.success) {
      notification({ type: 'success', duration: 1.5, msg: result.message })
      if (payload.removeClassFromList) {
        const dashboardClasses = yield select(getDashboardClasses)
        yield put(
          receiveTeacherDashboardSuccessAction(
            dashboardClasses.filter((x) => x._id !== payload.groupId)
          )
        )
      }
    } else {
      yield put(
        updatefavoriteClassesAction({
          groupId: payload.groupId,
          isFavourite: !payload.toggleValue,
        })
      )
      notification({
        type: 'error',
        duration: 1.5,
        msg: 'Failed to mark class as favourite.',
      })
    }
  } catch (e) {
    captureSentryException(e)
    yield put(
      updatefavoriteClassesAction({
        groupId: payload.groupId,
        isFavourite: !payload.toggleValue,
      })
    )
    notification({
      type: 'error',
      duration: 1.5,
      msg: 'Failed to mark class as favourite.',
    })
  }
}

function* fetchDashboardTilesSaga({ payload = {} }) {
  try {
    const { bannerType } = payload
    const version = localStorage.getItem('author:dashboard:version')
    const state = yield select(
      (s) => s.user.user?.orgData?.districts?.[0]?.districtState || ''
    )
    const user = yield select(getUserDetails) || {}
    const result = yield call(
      configurableTilesApi.fetchTiles,
      +version,
      state.toUpperCase(),
      user.utm_source === 'singapore' ? true : undefined,
      bannerType
    )
    if (!version || version !== result.version) {
      if (bannerType === FEATURE_BANNER) {
        yield put(setFeatureBanner(result.data))
      } else {
        yield put(setDashboardTiles(result.data))
        const featureBanners = result.data.filter(
          (banner) => banner.type === FEATURE_BANNER
        )
        yield put(setFeatureBanner(featureBanners))
        localStorage.setItem(
          'author:dashboard:tiles',
          JSON.stringify(result.data)
        )
      }

      localStorage.setItem('author:dashboard:version', +result.version)
    }
  } catch (err) {
    console.log(err)
    const errorMessage = 'Unable to fetch dashboard details.'
    notification({ type: 'error', msg: errorMessage })
    yield put(receiveTeacherDashboardErrorAction({ error: errorMessage }))
  }
}

function* fetchPlaylistsSaga({ payload }) {
  try {
    const result = yield call(
      curriculumSequencesApi.searchCurriculumSequences,
      payload
    )
    yield put(fetchPlaylistsSuccessAction(result.hits.hits))
  } catch (err) {
    notification({ type: 'error', msg: 'Unable to fetch playlists details.' })
  }
}

function* setDashboardSettingsSaga({ payload }) {
  try {
    yield put({
      type: UPDATE_DASHBOARD_SETTINGS,
      payload,
    })
    yield call(userContextApi.setDashboardVersion, payload)
  } catch (err) {
    Sentry.captureException(err)
  }
}

function* getDashboardAssignmentsSaga({ payload }) {
  try {
    const currentTermIds = yield select(getCurrentActiveTermIds)
    const defaultTermId = yield select(getCurrentTerm)
    const termId = currentTermIds?.[0] || defaultTermId
    payload.termId = termId
    let result = []
    if (payload.termId) {
      result = yield call(
        assignmentApi.fetchTeacherDashboardAssignments,
        payload
      )
    }
    const processedResult = result.data.map((testAssignments) => {
      const testHasRandomQuestions =
        hasRandomQuestions(testAssignments) ||
        testAssignments.testCategory === testCategoryTypes.ADAPTIVE_SECTION_TEST
      const hasAutoSelectGroups = testAssignments.itemGroups.some(
        ({ type }) => type === ITEM_GROUP_TYPES.AUTOSELECT
      )
      const assignmentVisibility = []
      const testStats = {
        submitted: 0,
        graded: 0,
        total: 0,
      }
      const updatedClasses = testAssignments.classes.map((c) => {
        const { testContentVisibility, stats } = c
        assignmentVisibility.push(
          testContentVisibility || testConstants.testContentVisibility.ALWAYS
        )
        testStats.submitted +=
          (stats?.inGradingNumber || 0) + (stats?.gradedNumber || 0)
        testStats.graded += stats?.gradedNumber || 0
        testStats.total += stats?.assignedCount || 0
        return {
          ...c,
          hasRandomQuestions: testHasRandomQuestions,
        }
      })
      return {
        ...testAssignments,
        key: testAssignments._id,
        stats: testStats,
        assignmentVisibility,
        hasAutoSelectGroups,
        classes: updatedClasses,
      }
    })
    yield put(setDashBoardAssignmentsAction(processedResult))
    yield put(setAssignmentsByTestAction(result.total))
  } catch (err) {
    notification({ type: 'error', msg: 'Could not load assignments' })
    yield put(setDashboardAssignmentsErrorAction({ err }))
  }
}

export function* watcherSaga() {
  yield all([
    yield takeEvery(
      RECEIVE_TEACHER_DASHBOARD_REQUEST,
      receiveTeacherDashboardSaga
    ),
    yield takeEvery(FETCH_DASHBOARD_TILES, fetchDashboardTilesSaga),
    yield takeEvery(FETCH_PLAYLIST, fetchPlaylistsSaga),
    yield takeEvery(TOGGLE_FAVORITE_CLASS, toggleFavoriteClassSaga),
    yield takeEvery(GET_DASHBOARD_ASSIGNMENTS, getDashboardAssignmentsSaga),
    yield takeEvery(SET_DASHBOARD_SETTINGS, setDashboardSettingsSaga),
  ])
}
