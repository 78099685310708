import * as Sentry from '@sentry/browser'
import * as firebase from 'firebase/app'
import { TokenStorage } from '@edulastic/api'
import appConfig from './app-config'

export const EXTERNAL_TOKEN = 'extt'
export const REDIRECT_TO = 'redirectTo'

export const captureSentryException = (err) => {
  // Ignore BE's business errors
  if (!err || (err && [409, 302, 422, 403].includes(err.status))) {
    return
  }

  Sentry.captureException(err)
}

export const storeUserAuthToken = ({
  userToken,
  firebaseToken,
  _id: userId,
  role,
}) => {
  if (userToken && userId && role) {
    TokenStorage.storeAccessToken(userToken, userId, role)
    TokenStorage.selectAccessToken(userId, role)
    if (firebaseToken) {
      firebase.auth().signInWithCustomToken(firebaseToken)
    }
  } else if (userId && role) {
    TokenStorage.selectAccessToken(userId, role)
  }
}

export const getExternalAuthToken = () => {
  const queryParams = new URLSearchParams(window.location.search)
  return queryParams.get(EXTERNAL_TOKEN)
}

export const getRedirectTo = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const redirectTo = queryParams.get(REDIRECT_TO)

  if (redirectTo) {
    try {
      const rootDomain = new URL(redirectTo).hostname
        .split('.')
        .slice(-2)
        .join('.')

      const allowedRedirectDomain = appConfig.redirectToAllowedRootDomains.includes(
        rootDomain
      )

      if (!allowedRedirectDomain) {
        throw new Error('Invalid redirect URL.')
      }
    } catch (error) {
      return null
    }
  }

  return redirectTo
}
